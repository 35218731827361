<template>
  <div class="box">
    <div class="box_box">
      <div class="text">
        <div>回瓶核销系统</div>
        <div>欢迎登录：{{ username }}</div>
      </div>
      <div class="list">
        <el-input v-model="sn" placeholder="请扫描序列号" ref="autoFocus" clearable @change="ok(sn)" />
      </div>
    </div>
  </div>
</template>


<script>
import { snApi } from '@/api/api.js'
export default {
  data() {
    return {
      sn: "",
      username: ''
    };
  },
  created() {
    this.$nextTick((_) => {
      this.$refs.autoFocus.focus();
    })
    this.username = this.$route.query.name;
  },
  methods: {
    ok(item) {
      snApi({ sn: item }).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg)
          this.sn = ''
        } else {
          this.$message.error(res.msg)
          this.sn = ''
        }
      })
    }
  }
}
</script>

<style scoped>
.box {
  width: 100vw;
  height: 100vh;
  background: url("../assets/img/banner.jpg");
  background-position: center center;
  background-size: cover;

  .box_box {
    width: 95%;
    margin: 0 auto;
    padding-top: 20px;
  }

  .list {
    position: fixed;
    top: calc(50%);
    left: calc(25%);
    width: 50%;
  }


}

.text {

  font-size: 16px;
  color: white;
}

@media screen and (min-width: 320px) and (max-width: 1080px) {
  .text {

    line-height: 30px;
  }
}


@media screen and (min-width: 768px) and (max-width: 1536px) {
  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  }
}

@media screen and (min-width: 1366px) {

  .text {
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    align-items: center;
  }
}
</style>